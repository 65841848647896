import * as React from 'react';
import { Link, useStaticQuery, graphql, navigate } from 'gatsby';
import {
   container,
   containerTop,
   heading,
   navLinks,
   navLinkItem,
   navLinkText,
   cardCarouselContainer,
   clickableCardsHolder,
   clickableCard,
   bottomCard,
   shortDescription,
   wouldItWork,
   roundedImage,
   fullDiv,
   justCard,
   carousel1Winner,
   sectionBackground,
   card1Winner,
   card1textWinner,
   sectionTitle,
   ourImageBorderRadiusTop,
} from './cardCarousel.module.css';
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image';
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from 'styled-components';
import { MDXRenderer } from 'gatsby-plugin-mdx';

const Container = styled.div`
   background: ${(props) => props.primary};
   color: ${(props) => props.textColor};

}};
`;

const StyledCard = styled.div`
   border-radius: 15px 15px 15px 15px !important;
   box-shadow: ${(props) => props.theBoxShadow}};
   margin-bottom: 45px;
`;

const propBoxShadow =
   '0 20px 60px rgba(120, 120, 120, 0.4), 0 -10px 30px rgba(120, 120, 120, 0.15)';

const CardCarousel = ({
   theBoxShadow,
   sectionTitleText,
   backgroundColor,
   textColor,
   sourceInstanceNameProp,
   cardDataArray,
}) => {
   const data = useStaticQuery(graphql`
      query {
         allFile(
            sort: { fields: [childrenMdx___frontmatter___date], order: DESC }
            filter: { extension: { eq: "mdx" } }
         ) {
            nodes {
               childMdx {
                  frontmatter {
                     date(formatString: "MMMM D, YYYY")
                     title
                  }
                  id
                  slug
               }
               sourceInstanceName
            }
         }
      }
   `);

   console.log('cardCarousel', data);
   console.log('cardDataArray', cardDataArray);

   let click = () => {
      navigate('/blog');
   };

   return (
      <>
         <div className="biege">
            <Container primary={backgroundColor} textColor={textColor}>
               <br />
               <br />
               <p>{sourceInstanceNameProp}</p>
               <br />
               <div className={sectionTitle}>{sectionTitleText}</div>
               <div className="container">
                  <div className="row">
                     {cardDataArray.map((x) => (
                        <div className="col-md d-flex justify-content-center">
                           {/* <div onClick={() => navigate('/blog')}> */}
                           {/* <div onClick={() => navigate(x.childMdx.slug)}> */}
                           {/* {const winner = "hi";} */}
                           <div
                              onClick={() =>
                                 navigate(
                                    `${x.sourceInstanceName}/${x.childMdx.slug}`
                                 )
                              }
                           >
                              {/* <div onClick={() => navigate(x.sourceInstanceName)}> */}
                              {/* HII
                           <p>
                              {x.childMdx.frontmatter.title}
                              {console.log('x', x)}
                           </p>
                           HII */}
                              {/* <div>
                                 <button onClick={click}>Click Me</button>
                              </div> */}
                              <StyledCard theBoxShadow={theBoxShadow}>
                                 <div className={card1Winner}>
                                    {/* <StaticImage
                                    alt="FirstImage"
                                    src="../images/grapes.jpeg"
                                    height="120"
                                    width="225"
                                    imgStyle={{
                                       borderRadius: '15px 15px 0px 0px',
                                    }}
                                 /> */}
                                    <GatsbyImage
                                       image={getImage(
                                          x.childMdx.frontmatter.hero_image
                                       )}
                                       alt={
                                          x.childMdx.frontmatter.hero_image_alt
                                       }
                                       // className="testImageClass rounded-circle"
                                       // className="rounded-top"
                                       className={ourImageBorderRadiusTop}
                                    />
                                    <div className={card1textWinner}>
                                       {/* <h3>
                                       {x.childMdx.frontmatter.title.slice(
                                          0,
                                          18
                                       )}
                                    </h3> */}
                                       <h3>
                                          {x.childMdx.frontmatter.title.length <
                                          17
                                             ? x.childMdx.frontmatter.title
                                             : x.childMdx.frontmatter.title.slice(
                                                  0,
                                                  17
                                               ) + '...'}
                                          {/* // ) + '…'} */}
                                       </h3>
                                       {x.childMdx.excerpt}
                                       {/* <h3>Love + wonder</h3> */}
                                       {/* <MDXRenderer>
                                       {x.childMdx.excerpt}
                                    </MDXRenderer> */}
                                       {/* <MDXRenderer>{x.childMdx.body}</MDXRenderer> */}
                                       {/* word word word word word word word word word
                                    word word word word word word word word word
                                    word */}
                                    </div>
                                 </div>
                              </StyledCard>
                           </div>
                        </div>
                     ))}
                     {/* <div className="col-md d-flex justify-content-center">
                        <StyledCard theBoxShadow={theBoxShadow}>
                           <div className={card1Winner}>
                              <StaticImage
                                 alt="FirstImage"
                                 src="../images/grapes.jpeg"
                                 height="120"
                                 width="225"
                                 imgStyle={{
                                    borderRadius: '15px 15px 0px 0px',
                                 }}
                              />
                              <div className={card1textWinner}>
                                 <h3>Love + wonder</h3> word word word word word
                                 word word word word word word word word word
                                 word word word word word
                              </div>
                           </div>
                        </StyledCard>{' '}
                     </div>
                     <div className="col-md d-flex justify-content-center">
                        <StyledCard theBoxShadow={theBoxShadow}>
                           <div className={card1Winner}>
                              <StaticImage
                                 alt="FirstImage"
                                 src="../images/light.jpeg"
                                 height="120"
                                 width="225"
                                 imgStyle={{
                                    borderRadius: '15px 15px 0px 0px',
                                 }}
                              />
                              <div className={card1textWinner}>
                                 <h3>Love + wonder</h3> word word word word word
                                 word word word word word word word word word
                                 word word word word word
                              </div>
                           </div>
                        </StyledCard>{' '}
                     </div>
                     <div className="col-md d-flex justify-content-center">
                        <StyledCard theBoxShadow={theBoxShadow}>
                           <div className={card1Winner}>
                              <StaticImage
                                 alt="FirstImage"
                                 src="../images/fishv3.png"
                                 height="120"
                                 width="225"
                                 imgStyle={{
                                    borderRadius: '15px 15px 0px 0px',
                                 }}
                              />
                              <div className={card1textWinner}>
                                 <h3>Love + wonder</h3> word word word word word
                                 word word word word word word word word word
                                 word word word word word
                              </div>
                           </div>
                        </StyledCard>
                     </div> */}
                  </div>
               </div>
               <br />{' '}
            </Container>
         </div>
      </>
   );
};

export default CardCarousel;
