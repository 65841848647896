import * as React from 'react';
import Layout from '../components/layout';
import { StaticImage } from 'gatsby-plugin-image';
import '../styles/index.css';
import CardCarousel from '../components/cardCarousel';
import { Link, useStaticQuery, graphql } from 'gatsby';

const IndexPage = ({ data }) => {
   console.log('index.js-full-data', data);
   const [arrayOne, setArrayOne] = React.useState([]);
   const [arrayTwo, setArrayTwo] = React.useState([]);
   const [arrayThree, setArrayThree] = React.useState([]);

   const mysticalUnionString = 'mysticalUnion';
   const livingFromAboveString = 'livingFromAbove';
   const overcomingString = 'overcoming';

   React.useEffect(() => {
      if (data) {
         setArrayOne(
            data.allFile.nodes
               // .filter((x) => x.sourceInstanceName == '')
               .filter((x) => x.sourceInstanceName == mysticalUnionString)
               .slice(0, 3)
         );
         console.log('setArrayOne', arrayOne);
         setArrayTwo(
            data.allFile.nodes
               .filter((x) => x.sourceInstanceName == livingFromAboveString)
               .slice(0, 3)
         );
         console.log('setArrayTwo', arrayTwo);
         setArrayThree(
            data.allFile.nodes
               .filter((x) => x.sourceInstanceName == overcomingString)
               .slice(0, 3)
         );
         console.log('setArrayThree', arrayThree);
      }
   }, [data]);

   console.log('process.env.GATSBY_WHAT_FILE', process.env.GATSBY_WHAT_FILE);
   console.log('process.env.NODE_ENV', process.env.NODE_ENV);

   return (
      <>
         <Layout>
            <div className="containerBottom">
               <div className="theContainer">
                  <h1 className="theTitle">Christian Ecstaticism</h1>
                  <div className="theSubtitle">Living in the love of God</div>
                  <br />
               </div>
               <div className="centerImage">
                  <StaticImage
                     alt="StJoseph"
                     src="../images/StJoseph.jpg"
                     width="750"
                  />
               </div>
               <div className="imagesHolder">
                  <div>
                     <StaticImage
                        alt="FirstImage"
                        src="../images/StJoseph.jpg"
                        className="roundedImage"
                        width="250"
                     />
                  </div>
                  <div>
                     <StaticImage
                        alt="FirstImage"
                        src="../images/StJoseph.jpg"
                        className="roundedImage"
                        width="250"
                     />
                  </div>
                  <div>
                     <StaticImage
                        alt="FirstImage"
                        src="../images/StJoseph.jpg"
                        className="roundedImage"
                        width="250"
                     />
                  </div>
               </div>{' '}
            </div>
            <CardCarousel
               sectionTitleText="Mystical Union"
               backgroundColor="#fdf7ec"
               textColor="black"
               theBoxShadow="0 20px 60px rgba(76, 64, 46, 0.4),0 -10px 30px rgba(76, 64, 46, 0.5)"
               sourceInstanceNameProp="Level 1"
               cardDataArray={arrayOne}
            ></CardCarousel>
            <CardCarousel
               sectionTitleText="Living From Above"
               backgroundColor="#dd0531"
               textColor="yellow"
               theBoxShadow="0 20px 60px rgba(76, 64, 46, 0.4),0 -10px 30px rgba(76, 64, 46, 0.5)"
               sourceInstanceNameProp="Level 2"
               cardDataArray={arrayTwo}
            ></CardCarousel>
            <CardCarousel
               sectionTitleText="Overcoming"
               backgroundColor="#212429"
               textColor="white"
               theBoxShadow="0 20px 60px rgba(120, 120, 120, 0.4), 0 -10px 30px rgba(120, 120, 120, 0.15)"
               sourceInstanceNameProp="Level 3"
               cardDataArray={arrayThree}
            ></CardCarousel>
         </Layout>
      </>
   );
};

// export const query = graphql`
//    query {
//       allFile(
//          sort: { fields: [childrenMdx___frontmatter___date], order: DESC }
//          filter: { extension: { eq: "mdx" } }
//       ) {
//          nodes {
//             childMdx {
//                frontmatter {
//                   date(formatString: "MMMM D, YYYY")
//                   title
//                   category
//                   hero_image_alt
//                   hero_image {
//                      childImageSharp {
//                         gatsbyImageData(
//                            layout: CONSTRAINED
//                            width: 350
//                            height: 350
//                            transformOptions: {
//                               fit: COVER
//                               cropFocus: ATTENTION
//                            }
//                         )
//                      }
//                   }
//                }
//                id
//                slug
//                body
//                excerpt(pruneLength: 100)
//             }
//             sourceInstanceName
//          }
//       }
//    }
// `;

export const query = graphql`
   query {
      allFile(
         sort: { fields: [childrenMdx___frontmatter___date], order: ASC }
         filter: { extension: { eq: "mdx" } }
      ) {
         nodes {
            childMdx {
               frontmatter {
                  date(formatString: "MMMM D, YYYY")
                  title
                  category
                  hero_image_alt
                  hero_image {
                     childImageSharp {
                        gatsbyImageData(
                           layout: CONSTRAINED
                           width: 350
                           height: 300
                           transformOptions: {
                              fit: CONTAIN
                              cropFocus: ATTENTION
                           }
                        )
                     }
                  }
               }
               id
               slug
               body
               excerpt(pruneLength: 100)
            }
            sourceInstanceName
         }
      }
   }
`;

export default IndexPage;
